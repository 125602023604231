import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import MajorEcommercePlatformFeatured from 'images/featured-image/major-ecommerce-platforms.png';
import MajorEcommerceImgSrc from 'images/blog-images/major-ecommerce-platforms.png';
import OnlineShoppingImgSrc from 'images/blog-images/online-shopping-cart.png';
import AmazonOnlineRetailGrowth from 'images/blog-images/amazon-online-retail-growth.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query MajorEcommercePlatformsImg {
      file(relativePath: { eq: "online-shopping-cart.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="online shopping major ecommerce platforms"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="How Did The Major eCommerce Platforms Survive During the COVID-19 Pandemic? | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-how-did-the-major-ecommerce" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "How Did The Major eCommerce Platforms Survive During the COVID-19 Pandemic?",
              content: intl.formatMessage({ id: "blog-post-meta-title-how-did-the-major-ecommerce" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: MajorEcommercePlatformFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="how-did-the-major-ecommerce-platforms-survive-during-the-covid-19-pandemic"
        title="How Did The Major eCommerce Platforms Survive During the COVID-19 Pandemic?"
        date="2020-06-11"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              With the vigorous development of eCommerce during the COVID-19 pandemic, some platforms have benefited greatly, some of which have begun to plan for the future, while others are caught off guard. Etsy, Amazon, Walmart and, eBay are the four big winners. They each grew for different reasons, attracted more sellers, and increased market share.
            </p>

            <Image src={MajorEcommerceImgSrc} className="" alt="major ecommerce platforms"/>
            <p className="small">
              Image Source: <a href="https://stock.tuchong.com/" target="_blank" rel="noreferrer">https://stock.tuchong.com/</a>
            </p>

            <p>
              Facebook and Shopify are hopeful. Shopify focuses on self-built sites instead of eCommerce platforms. Facebook is different from the two of them, which focus on ideas and content. Both of these could become key players in the future. However, the performance of Wish, Google Shopping and Target is not as good as what people expected. Wish is in trouble because of its dependence on Chinese sellers, Google Shopping has not taken any obvious measures, and Target has been unable to flourish because the sellers are too small.
            </p>

            <p>
              Despite delivery problems, Amazon achieved growth during the pandemic. Amazon Chief Financial Officer Brian Olsavsky said:“Beginning in early March, we experienced a major surge in customer demand. This large demand spike created major challenges in our operations network.” Amazon’s online sales soared 24% in the first quarter, the fastest pace in four years. As sellers turned to deal with delivery issues, customer complaints hit a new high, but despite this, market growth remained strong for Amazon.
            </p>

            <Image src={AmazonOnlineRetailGrowth} alt="amazon online retail growth"/>
            <p className="small">
              Source: <a href="https://www.marketplacepulse.com/articles/amazon-sales-up-24-fastest-growth-in-four-years" target="_blank" rel="noreferrer">https://www.marketplacepulse.com/articles/amazon-sales-up-24-fastest-growth-in-four-years</a>
            </p>

            <p>
              In terms of percentage growth, Walmart outperformed eBay and Amazon. The number of visits to Walmart's website and the use of its applications has increased significantly, mainly due to the increased demand for online groceries. However, with the increase in passenger traffic, consumers no longer only focus on groceries, so Walmart also has to deal with this demand. The number of sellers in Walmart also increases slightly, approaching 45,000.
            </p>

            <p>
              In 2019, Target launched a platform called Target+. In the beginning, there were only 30 merchants, but after more than a year, it increased to 150. Target's performance as an online retailer in the past few years mainly comes from its use of physical stores to deliver products. The company said physical stores completed nearly 80% of Target’s first-quarter digital sales last month. Therefore, although a small number of merchants participating in the platform reported abnormal growth, the platform is still small compared to others.
            </p>

            <p>
              Wish was most affected by the pandemic. Due to the disruption of China's manufacturing and logistics, the platform's sales have dropped significantly. After all, Wish mainly depends on Chinese sellers, who account for more than 90% of all sellers. By May, sales began to recover, and the company has renewed its efforts to recruit sellers in the United States. However, the decline in sales will take some time to recover. At present, diversifying the seller structure is a major change.
            </p>
            
            <Image src={OnlineShoppingImgSrc} alt="online shopping" />

            <p>
              During the outbreak of COVID-19, Google announced that it will reintroduce free listing in the Google Shopping service. The service combines the shopping comparison experience with the local market, which was previously known as Google Express. There are 5,000 sellers on this platform, compared with 1,500 a year ago. However, it has not accelerated the absorption of more sellers or improved the poor shopping experience. In addition to announcing free listings, Google has not responded to the explosive growth of e-commerce, but only as an important advertising channel.
            </p>

            <p>
              Facebook introduces the Shops function, which can turn the business homepage into a store, and can do platform shopping without leaving Facebook and Instagram applications. Facebook and Google are in a similar situation-they both have billions of active users, but they all make purchases through external websites. Google added sellers and listings to try to solve this problem by focusing on supply, while Facebook solved the demand by creating ads and integrating storefront functions. There has been a lot of business activity on Facebook and Instagram, which turns it into in-app shopping.
            </p>

            <p>
              In April, Shopify launched a consumer shopping app called Shop. The application looks more like a platform, although the company has denied this. Shopify store traffic increased during the outbreak of COVID-19, so the growth of GMV in the second quarter will exceed 46% compared to the first quarter.
            </p>

            <p>
              In addition to the ones mentioned above, there are many other powerful platforms. Driven by the overall growth of eCommerce, most companies performed well. Some companies focus on one specific category or customer group, and by having a niche market, they are also growing while resisting Amazon competition.
            </p>
            
            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;